import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ["button", "file", "text", "icon", "action"]

    handleAttachment(e) {
        // // console.log('file -> ', e.target.files[0].name)
        this.iconTarget.classList.add('text-green-500')
        this.actionTarget.innerHTML = 'Change'
        this.textTarget.innerHTML = e.target.files[0].name
    }


}

import { Controller } from 'stimulus'

export default class extends Controller {

  connected() {
    // console.log('format number initialized')
  }

  formatNumber(event) {
    var target = event.target
    var input = target.value.replace(/\D/g, '')
    target.value = input
  }
}
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ["button", "checkbox"]

    initialize() {
        this.notAcknowledged()
    }

    verifyAcknowledged() {
        // // console.log(this.checkboxTarget.checked)
        if (this.checkboxTarget.checked == true) {
            this.acknowledged()
        } else {
            this.notAcknowledged()
        }

    }

    acknowledged() {
        this.buttonTarget.disabled = false
        this.buttonTarget.classList.remove('opacity-50', 'pointer-events-none')
    }

    notAcknowledged() {
        this.buttonTarget.disabled = true
        this.buttonTarget.classList.add('opacity-50', 'pointer-events-none')
    }
}
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['buttonText', 'radio']

    initialize() {
        this.refresh = this.refresh.bind(this)
    }

    connect() {

        // console.log('radio controller connected')
        this.buttonTextTarget.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white capitalize">All</span>'
        // console.log('button', this.buttonTextTarget)
        this.radioTargets.forEach(radio => radio.addEventListener('change', this.refresh))
    }

    refresh(e) {
        // console.log('radio targets', e.target)
        this.buttonTextTarget.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white capitalize">' + e.target.value + '</span>'
        // this.updateSelection(e.target)
    }

    updateSelection(e) {
        // console.log('updateselection')
        var input = e.target
        // console.log(e.target.value)
        this.updateButton(input.value)

    }

    updateButton(value) {

        this.buttonTextTarget.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white capitalize">' + value + '</span>'


    }
}
// app/javascript/controllers/notification_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["buttons", "countdown"]

  initialize() {
    this.hide()
  }

  connect() {
    const timeoutSeconds = parseInt(this.data.get("timeout"));

    setTimeout(() => {
      this.show()
    }, 100)
    setTimeout(() => {
      this.close();
    }, timeoutSeconds * 1000 + 500);
  }


  close() {
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 1100)

  }

  show() {
    this.element.classList.remove('translate-y-32', 'sm:-translate-y-32')
    this.element.classList.add('translate-y-0')

  }

  hide() {

    this.element.classList.add('translate-y-32', 'sm:-translate-y-32')
    this.element.classList.remove('translate-y-0')

  }
}
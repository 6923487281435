import { Controller } from "stimulus"
export default class extends Controller {
    static targets = [
        "q1",
        "q2",
        "q3",
        "q4",
        "q5",
        "q6",
        "q7",
        "q8",
        "preqButton",
        "notQualified",
        "qualified"
    ]

    connect() {
        this.disableLink()
        this.clearQualified()
        // console.log("preq controller connected!", this.preqButtonTarget)
    }

    handleSelection(e) {


        if (this.q4Target.value == 'yes') {
            this.hide(this.q5Target)
            this.hide(this.q6Target)
            this.show(this.q7Target)
        }
        if (this.q4Target.value == 'no') {
            this.hide(this.q7Target)
            this.hide(this.q8Target)
            this.show(this.q5Target)

        }
        if (this.q5Target.value == 'no') {
            this.show(this.q6Target)
        }
        if (this.q5Target.value == 'yes') {
            this.hide(this.q6Target)
            this.show(this.q7Target)
        }
        if (this.q6Target.value == 'no') {
            this.hide(this.q7Target)
            this.hide(this.q8Target)
        }
        if (this.q6Target.value == 'yes') {
            this.show(this.q7Target)
        }
        if (this.q7Target.value == 'no') {
            this.show(this.q8Target)
        }
        this.handleQualification()
    }

    handleQualification() {
        this.clearQualified()

        if (this.q1Target.value == 'no') {
            this.notQualified()
        }
        if (this.q2Target.value == 'yes') {
            this.notQualified()
        }
        if (this.q6Target.value == 'no') {
            this.notQualified()
        }
        if (this.q7Target.value == 'yes') {
            this.qualified()
        }
        if (this.q8Target.value == 'yes') {
            this.notQualified()
        }
        if (this.q8Target.value == 'no') {
            this.qualified()
        }




    }
    clearQualified() {
        this.qualifiedTarget.classList.add('hidden')
        this.notQualifiedTarget.classList.add('hidden')
    }
    notQualified() {

        this.notQualifiedTarget.classList.remove('hidden')
        this.disableLink()
    }

    qualified() {
        this.qualifiedTarget.classList.remove('hidden')
        this.enableLink()
    }

    validatePrequalification() {
        if (this.q7Target.value == 'yes' || this.q8Target.value == 'no') {
            this.enableLink()
        } else {
            this.disableLink()
        }

    }

    hide(t) {
        this.findDiv(t).classList.add('hidden')
    }

    show(t) {
        this.findDiv(t).classList.remove('hidden')
    }

    disableLink() {
        this.preqButtonTarget.classList.add('pointer-events-none', 'opacity-50')
    }

    enableLink() {
        this.preqButtonTarget.classList.remove('pointer-events-none', 'opacity-50')
    }

    findDiv(t) {
        return t.closest("div")
    }
}
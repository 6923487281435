import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['maskable']

  connect() {
    // // console.log('mask controller initialized')
    this.maskableTarget.type = 'password'
    // this.calculateAge()
  }


  unmask(e) {
    var field = e.target
    field.type = 'text'
    // // console.log("I solemnly swear that I am up to no good.")
  }

  mask(e) {
    var field = e.target
    field.type = 'password'
    // // console.log('mischief managed')
  }
}
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['list', 'input', 'selection']

  connect() {
    // // console.log('cancel controller initialized')



  }

  checkSelection(e) {
    var input = e.target
    if (e.target.value == 'Other') {
      this.inputTarget.classList.remove('hidden')
    } else {
      this.inputTarget.classList.add('hidden')
    }

  }


}
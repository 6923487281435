import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['dob', 'age']

  connect() {
    // // console.log('age controller initialized')
    this.calculateAge()
  }

  calculateAge() {
    var today = new Date();
    var birthDate = new Date(this.dobTarget.value);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (today < birthDate) {
      this.dobTarget.classList.remove("focus:ring-secondary", "focus:border-secondary-500", "shadow-sm", "border-gray-400")
      this.dobTarget.classList.add("ring-1", "ring-mde-pink-500", "border-mde-pink-500")
      this.ageTarget.value = ""
    } else {
      this.dobTarget.classList.add("focus:ring-secondary", "focus:border-secondary-500", "shadow-sm", "border-gray-400")
      this.dobTarget.classList.remove("ring-1", "ring-mde-pink-500", "border-mde-pink-500")
      this.ageTarget.value = age
    }
  }

  findMessage(t) {
    return t.closest("div").querySelector(".error-message")
  }
}
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['checkboxAll', 'checkbox', 'buttonText']

    initialize() {
        this.toggle = this.toggle.bind(this)
        this.refresh = this.refresh.bind(this)
        this.buttonTextTarget.innerHTML = '<span class="block truncate">All</span>'
    }

    connect() {
        if (!this.hasCheckboxAllTarget) return

        this.checkboxAllTarget.addEventListener('change', this.toggle)
        this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', this.refresh))
        this.refresh()
    }

    // updateValue() {
    //     if (this.checkBoxTargets)
    // }

    disconnect() {
        if (!this.hasCheckboxAllTarget) return

        this.checkboxAllTarget.removeEventListener('change', this.toggle)
        this.checkboxTargets.forEach(checkbox => checkbox.removeEventListener('change', this.refresh))
    }

    updateButton() {
        if (this.checked.length == 0) {
            this.buttonTextTarget.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white">No flags</span>'
        } else if (this.checked.length == 1) {
            this.buttonTextTarget.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white">' + this.checked[0].value + '</span>'
        } else if (this.checked.length > 1 && this.checked.length < 3) {
            this.buttonTextTarget.innerHTML = '<div class="flex items-center space-x-2 text-white text-xs"><span class="block truncate text-white bg-mde-blue-600 rounded-full py-0.5 px-2">' + this.checked[0].value + '</span><span class="block truncate bg-mde-blue-600 rounded-full py-0.5 px-2">' + this.checked[1].value + '</span></div>'
        } else if (this.checked.length > 3 && this.checked.length < this.checkboxTargets.length) {
            this.buttonTextTarget.innerHTML = '<div class="flex items-center space-x-2"><span class="block truncate text-white text-xs bg-mde-blue-600 rounded-full px-2 py-0.5">' + this.checked[0].value + '</span><span class="text-gray-600 text-xs"> + ' + (this.checked.length - 1) + ' more</span</div>'
        } else {
            this.buttonTextTarget.innerHTML = '<span class="block truncate px-2 py-0.5 text-xs bg-mde-blue-600 text-white rounded-full">All</span>'
        }
    }



    toggle(e) {
        e.preventDefault()

        // // console.log('checkBoxesCheckedCount => ', this.checked.length)

        this.checkboxTargets.forEach(checkbox => {
            checkbox.checked = e.target.checked
        })

        // // console.log('checkBoxesCheckedCount => ', this.checked.length)
        this.updateButton()
    }

    refresh() {
        const checkboxesCount = this.checkboxTargets.length
        const checkboxesCheckedCount = this.checked.length

        // // console.log('checkBoxesCheckedCount => ', checkboxesCheckedCount)
        // if (checkboxesCheckedCount == 0) {
        //     // console.log('checkBoxesCheckedCount => ', checkboxesCheckedCount.length)
        // }

        this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
        this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
        this.updateButton()
    }

    get checked() {
        return this.checkboxTargets.filter(checkbox => checkbox.checked)
    }

    get unchecked() {
        return this.checkboxTargets.filter(checkbox => !checkbox.checked)
    }
}
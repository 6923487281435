import { Controller } from "stimulus"
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = ['statusButton', 'exportPdf', "results", "form", "startDate", "endDate", 'flag', 'checkbox', 'radio']

  connect() {
    document.addEventListener("keydown", this.preventSubmitHandler.bind(this))
    this.bounce = debounce(this.search, 300).bind(this)
  }

  disconnect() {
    document.removeEventListener("keydown", this.preventSubmitHandler.bind(this))
  }

  preventSubmitHandler(event) {
    if (event.keyCode == 13) {

      event.preventDefault();
      return false;
    }
  }

  search() {
    this.formTarget.setAttribute('data-remote', true)
    Rails.fire(this.formTarget, 'submit')
    this.formTarget.setAttribute('data-remote', false)
  }

  handleResults() {
    const [data, status, xhr] = event.detail
    if (xhr.getResponseHeader("Content-Type") == 'application/pdf') {
      // // console.log('issa pdf', xhr)
      this.buildPdf(data)
    } else if (xhr.getResponseHeader("Content-Type") == 'text/csv') {
      this.buildCsv(data)
    } else {
      this.resultsTarget.innerHTML = data
    }
  }

  buildPdf(data) {
    var file = new Blob([data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);

  }

  buildCsv(data) {
    var file = new Blob([data], { type: 'text/csv' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);

  }

  getCalendarYearApplications() {
    var button = document.getElementById('flag_button')
    button.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white capitalize">All</span>'
    var today = new Date()
    this.startDateTarget.value = new Date(today.getFullYear(), 0, 1).toISOString().split('T')[0]
    this.endDateTarget.value = new Date(today.getFullYear(), today.getMonth(), today.getDate()).toISOString().split('T')[0]
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
    })
    this.radioTargets.forEach(radio => {
      if (radio.value == 'all') {
        radio.checked = true
      }
    })

    this.search()


  }

  getStaleApplications() {
    var button = document.getElementById('status_button')
    button.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white capitalize">New</span>'
    var staleDate = new Date(Date.now() - 12096e5);
    this.checkboxTargets.forEach(checkbox => {

      if (checkbox.value == 'new') {
        checkbox.checked = true
      } else {
        checkbox.checked = false
      }

    })
    this.endDateTarget.value = new Date(staleDate.getFullYear(), staleDate.getMonth(), staleDate.getDate()).toISOString().split('T')[0]
    this.search()

  }

  getCalendarYearDisqualifiedApplications() {
    var button = document.getElementById('flag_button')
    button.innerHTML = '<span class="block truncate py-0.5 px-2 text-xs rounded-full bg-mde-blue-600 text-white capitalize">Any Flag</span>'
    var today = new Date()
    this.startDateTarget.value = new Date(today.getFullYear(), 0, 1).toISOString().split('T')[0]
    this.endDateTarget.value = new Date(today.getFullYear(), today.getMonth(), today.getDate()).toISOString().split('T')[0]


    this.radioTargets.forEach(radio => {
      // // console.log('radio -> ', radio)
      if (radio.value == 'Any flag') {
        radio.checked = true
      }
    })

    this.search()



  }
}
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'input', 'results', 'selected', 'showPhone', 'phoneField', 'inputField']

  connected() {
    // console.log('format phone initialized')

  }

  handlePhoneInput(event) {
    var target = event.target
    var input = target.value.replace(/\D/g, '').substring(0, 10)
    this.phoneFieldTarget.value = input
    // console.log('phonefield', this.phoneFieldTarget.value)
    var areaCode = input.substring(0, 3)
    var middle = input.substring(3, 6)
    var last = input.substring(6, 10)
    // target.value = input

    // const input = target.value.replace(/\D/g, '').substring(0, 10)
    // const areaCode = input.substring(0, 3)
    // const middle = input.substring(3, 6)
    // const last = input.substring(6, 10)

    if (input.length > 6) {
      target.value = `(${areaCode}) ${middle}-${last}`
    } else if (input.length > 3) {
      target.value = `(${areaCode}) ${middle}`
    } else if (input.length > 0) {
      target.value = `(${areaCode})`
    } else {
      target.value = ''
    }
  }
}

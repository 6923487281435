// app/javascript/controllers/listbox_controller.js

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'input', 'results', 'schoolSearch', 'selected', 'previousSchool', 'previousDistrict', 'enrollment']

  connect() {
    // console.log('schools initialized', this.previousSchoolTarget.value)
    if (this.previousSchoolTarget.value) {
      // console.log('previous school -> ', this.previousSchoolTarget.value)
      this.showButton()
      if (this.previousSchoolTarget.value == '00001') {
        this.enrollmentTarget.classList.remove('hidden')
      }
    } else {
      this.showInput()
    }
  }

  initialize() {
    // console.log('initialized')
  }

  checkAttended(e) {
    var input = e.target
    // console.log('checkattended -> ', input.checked)
    if (input.checked == true) {
      this.schoolSearchTarget.classList.add('hidden')
      this.previousSchoolTarget.value = 'none'
    } else {
      this.schoolSearchTarget.classList.remove('hidden')
    }
  }

  fetchResults() {
    this.resultsTarget.classList.remove('hidden')
    if (this.query == "") {
      // this.reset()
      return
    }

    if (this.query == this.previousQuery) {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.data.get("url"))
    url.searchParams.append("query", this.query)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => { })
  }

  get query() {
    return this.inputTarget.value
  }

  abortPreviousFetchRequest() {
    if (this.abortController) {
      this.abortController.abort()
    }
  }

  selectSchool(e) {
    var target = e.target
    var selectedSchool = ''
    var selectedDistrict = ''

    // console.log('target -> ', target.dataset.school)

    if (target.dataset.school) {
      selectedSchool = JSON.parse(target.dataset.school)
      selectedDistrict = JSON.parse(target.dataset.district)
      // console.log('school selected', selectedSchool)
      this.updateButton(selectedSchool, selectedDistrict)
    } else {
      this.showButton()
      this.buttonTarget.innerHTML = '<div class="flex flex-col items-start px-3">' +
        '<h3 class="text-gray-900 text-sm font-medium">Student did not attend any school</h3>' +
        '</div>'

    }
    if (selectedSchool.entity_row_id == '00001') {
      // console.log('magnolia selected')
      this.enrollmentTarget.classList.remove('hidden')
      this.enrollmentTarget.focus()
    } else {
      this.enrollmentTarget.classList.add('hidden')
    }

  }

  updateButton(school, district) {
    this.showButton()
    // console.log('updatedbutton entity -> ', school)
    this.buttonTarget.innerHTML = '<div class="flex flex-col items-start px-3">' +
      '<h3 class="text-gray-900 text-sm font-medium">' +
      school.entity_display_name +
      '<span class="ml-1 text-gray-500 font-normal"> (' +
      school.entity_city + ', ' + school.entity_state +
      ')</span></h3>' +
      '<p class="text-gray-500 text-xs truncate">' + district.entity_display_name + '</p>' +
      '</div>'

    this.previousSchoolTarget.value = school.entity_row_id
  }

  hide(event) {
    if (this.element.contains(event.target) === false) {
      this.resultsTarget.classList.add('hidden')
    }
  }

  showButton() {
    this.inputTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('hidden')
    this.resultsTarget.classList.add('hidden')

  }
  showInput() {
    this.inputTarget.value = ''
    this.inputTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
    this.inputTarget.focus()

  }
}
import { Controller } from 'stimulus'

export default class extends Controller {

    static targets = ['overlay']

    connect() {
        // // console.log('cookie controller initialized')
        if (this.getCookie()) {
            // // console.log('cookie exists')
            this.closeCookie()
        } else {
            this.setCookie()
            this.openCookie()
        }

    }

    setCookie() {
        var expires = "";


        var date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();

        document.cookie = "mde-mesp-consent=" + "" + expires + "; path=/";
    }

    getCookie() {
        var nameEQ = "mde-mesp-consent";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    closeCookie() {
        this.overlayTarget.classList.add('hidden')
    }

    openCookie() {
        this.overlayTarget.classList.remove('hidden')
    }
}